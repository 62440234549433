body{
  background-color: lightgrey !important;
}

a:visited{
  color: black;
}

.heading-padding {
  padding: 18px
}

.search-result-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-title {
  font-size: 24px; 
  margin-bottom: 20px; 
  color: #05101b;
}

.result-container {
  width: 80%;
  margin-top: 30px;
}

.result-item {
  border: 2px solid #ddd;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  width: 90%; 
  max-width: 800px; 
  color:#05101b;
  background-color: white !important;
}

.item-details {
  display: flex;
  width: 100%;
}

.column {
  flex: 1;
  margin-right: 40px;
}

.right-column {
  margin-right: 0;
}

.result-item:not(:last-child) {
  margin-bottom: 40px;
}

.column strong {
  font-size: 24px;
}

.column,
.right-column {
  font-size: 20px;
  padding: 20px;
}

.outdated-warning {
  color: red;
}

.result-itemt:nth-child(odd) {
  background-color: white; 
}

.result-itemt:nth-child(even) {
  background-color: grey; 
}

.small-buttons{
  font-size: '10px';
  padding: '3px 3px'
}


.suggestion-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin-bottom: 10px; 
}

.error {
  color: red;
}

@media only screen and (max-width: 400px) {
  .MuiTableCell-root {
    font-size: 13px  !important;
    padding: 10px !important;
  }
}

.chartBox {
  width: 700px;
  
  background: white;
  min-height: 400px ;
  min-width: 100% ;
}

.suggestionsBox{
  width: 450px;
  height: 150px;
  box-sizing: border-box;
}

@media only screen and (max-width: 900px) {
  .chartBox {
    width: 550px;
    height: 300px !important;
    background: white;
    min-height: 200px !important;
    min-width: 100% !important;
  }
  .result-item {
    padding: 25px;
  }
}

@media only screen and (max-width: 800px) {
  .chartBox {
    width: 450px;
    height: 300px !important;
    background: white;
    min-height: 200px !important;
    min-width: 100% !important;
  }
  .result-item {
    padding: 25px;
  }
}

@media only screen and (max-width: 700px) {
  .chartBox {
    width: 350px;
    height: 300px !important;
    background: white;
    min-height: 200px !important;
    min-width: 100% !important;
  }

  .result-item {
    padding: 25px;
  }

  .suggestionsBox{
    width: 400px;
    height: 150px;
  }
}

@media only screen and (max-width: 450px) {
  .suggestionsBox{
    width: 300px;
    height: 150px;
  }
}


@media only screen and (max-width: 450px) {
  .chartBox {
    width: 150px;
    height: 300px !important;
    background: white;
    min-height: 200px !important;
  
  }
  .result-item {
    padding: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .chartBox {
    width: 150px;
    height: 300px !important;
    background: white;
    min-height: 200px !important;
  
  }
  .result-item {
    padding: 5px;
  }

  .suggestionsBox{
    width: 200px;
    height: 150px;
  }
}



.App {
  text-align: center;
  min-height: 100vh !important;
  display: 'flex';
  flex-direction: 'column';
}

@media only screen and (max-width: 1100px) {
  .App {
    text-align: center;
    min-height: 110vh !important;
  }
}

@media only screen and (max-width: 550px) {
  .App {
    text-align: center;
    min-height: 120vh !important;
  }
}

@media only screen and (max-width: 400px) {
  .App {
    text-align: center;
    min-height: 125vh !important;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.custom-column {
  /* border-left: 1px solid #e0e0e0; /* or your desired color */
  border-left: 2px solid #e0e0e0;
  letter-spacing: 0px;
}
.MuiDataGrid-menuIcon {
  display: none;
}

.custom-outdated-price-row{
  background-color: #ffcc00;
}
.custom-high-price-row {
  background-color: #d2ebc7;
}

.custom-low-price-row {
  background-color: #FFCCCB;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  background-color: inherit;
  overflow: visible !important;
  display: block !important;
  overflow-wrap: break-word !important;
  white-space: normal;
}

.custom-data-grid .MuiDataGrid-colCellTitle {
  overflow-wrap: break-word;
}

.custom-data-grid {
  background-color: white !important;
}

.mui-table-container {
  border: 2px solid #ddd;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  color:#05101b;
  background-color: white !important;
}


.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
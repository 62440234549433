.nav-links {
    color: white !important; /* Text color */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition effect */
    font-size: 20px !important;
  }
  
  /* Hover styling for navigation links */
  .nav-links:hover {
    background-color: rgba(255, 255, 255, 0.2) !important; /* Background color on hover */
    color: black !important; /* Text color on hover */
    border-radius: 5px; /* Rounded corners on hover */

  }